import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

export default () => {
  return (
    <Layout>
      <SEO title="Not Found" description="Page not found" />
      <h1>Page Not Found</h1>
      <p>Oops! The page you are looking for has been moved or relocated.</p>
      <p>
        <Link to="/">Go Back</Link>
      </p>
    </Layout>
  )
}
